<template>
  <div>
    <pdf ref="pdf" class="pdf-preview"
         v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
    <div style="height: 200px"></div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
import { Toast } from 'vant';
export default {
  components: {
    pdf
  },
  data() {
    return {
      width:0,
      height:0,
      url:"",
      numPages: null, // pdf 总页数
    }
  },
  methods:{
    /**
     获取请求中携带的参数
     */
    getUrlParameter(){
      let hash = window.location.hash;
      let data = hash.slice(hash.indexOf('?') + 1);
      let pairs = data.split('&');
      let params = {};
      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        params[key] = value;
      }
      sessionStorage.setItem("appToken",params.appToken);
      sessionStorage.setItem("nowTime",params.nowTime);
      return params;
    }
  },
  created() {
    Toast.loading({
      message: '请稍等...',
      forbidClick: true,
      loadingType: 'spinner',
      duration:1000
    });
    let data = this.getUrlParameter();
    this.url = data.url;
    let loadingTask = pdf.createLoadingTask(this.url)
    loadingTask.promise.then(pdf => {
      this.numPages = pdf.numPages;
    })
    this.width = document.documentElement.clientWidth-110; //实时宽度
    this.height = document.documentElement.clientHeight-50; //实时高度
  }
}
</script>

<style scoped>
.pdf-preview {
  width: 100%;
//flex: 1;
//display: none;
  height: 78vh;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78%;
}

.block {
  transform: rotate(90deg);
  width: 300px;
  height: 120px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 0;
}
.pdf-preview canvas {
  height: 78% !important;
}
.cancel{
  width: 50%;border-right: 1px solid #E6E8EB;padding: 8px 0
}
.confirm{
  color: #67C23A;
  width: 50%;
  padding: 8px 0
}
.sign-final{
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.sign-div{
  bottom: 0;
  padding:10px 20px 20px 20px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 100%;
}
.consent{
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  line-height: 24px;
}
.sign-button{
  width:150px;
  height: 46px;
  border-radius: 16px;
  border: none;
}
</style>