import axios from 'axios';
const service = axios.create({
    // 若瑶：22  曹：20   腾辉：43
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // baseURL: 'http://192.168.30.20:9099',
    baseURL: 'https://minprogram.yanhuotiyu.com',
    timeout: 1000000,
    contentType: "application/x-www-form-urlencoded"
});
service.interceptors.request.use(
    config => {
        // console.log(config)
        let token = localStorage.getItem('token')
        if (token && (config.url!='/sys/login' || config.url!='/sys/getAuthCode')) {
            config.headers['token'] = token;
        }
        return config;
    },
    error => {
        console.log(error.response.data);
        return Promise.reject();
    }
);
service.interceptors.response.use(
    response => {
        if (response.data.code===1030){

            return ;
        }
        if (response.data.code===5100){

            return ;
        }
        if (response.data.code===403){

            return ;
        }
        if (response.status == 200) {

            return  response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        // console.log(error.response.data);
        // return
        if (error.response.data.code == 401){
            localStorage.removeItem("token")
            window.location="/login"
        }
        return Promise.reject();
    }
);
export default service;

