import request from './request'

export const importExcelResultExcel = (file) => {
    return request({
        url: '/api/sys/sportTest/importExcel',
        method: 'POST',
        data: file,
        headers: {
            "Content-type": "multipart/form-data",
        },
        responseType: 'arraybuffer',
    });
};
