<template>
  <div style="width: 600px;height: 700px;margin: 0 auto;">

      <div style="display:flex;justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="margin: 10px 0;display:flex;justify-content: center">
        <div style="display: flex;justify-content: space-between;width: 360px">
        </div>
      </div>

      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button-group>
          <el-button type="primary" style="margin-left: 10px"><a href="https://codeimg.yanhuotiyu.com/YanHuoAppImages/SportTestExcel/%E9%80%9A%E7%94%A8%E4%BD%93%E6%B5%8B%E6%88%90%E7%BB%A9-%E5%AD%A6%E5%91%98%E5%AF%BC%E5%85%A5-%E6%88%90%E7%BB%A9%E8%AE%A1%E7%AE%97-%E6%A8%A1%E7%89%88.xlsx">下载导入模板</a></el-button>
          <el-button type="primary"  @click='confirmUpload' style="margin-left: 10px">一键下载体测得分</el-button>
        </el-button-group>
      </div>
      <div>
        <div style="text-align: left">
          1.下载体测成绩模版,
          <br/>
          2.按照模版和各项的单位补充学员信息和各项成绩不要修改表头
          <br/>
          3.选择上传的文件，
          <br/>
          注意事项：表头需保持一致，不一致将无法进行成绩得分的导出。
        </div>

      </div>
<!--    <UploadAndDownload/>-->
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
// import UploadAndDownload from './UploadAndDownload'
import {
  importExcelResultExcel
} from '@/request/file'


export default {
  name: "ImportStudentResultScore",
  components: {
    // UploadAndDownload,
  },
  data() {
    return {
      code: null,
      exportPhoneDialog: true,
      fileList: [],
      limitNum: 1, // 上传excel时，同时允许上传的最大数
      excelFile: null,
      note: '',
      number: 0,
    }
  },
  methods: {
    //确认上传
    confirmUpload() {
      let data = new FormData()
      data.append('file',this.excelFile)
      //导入体测学员信息
      importExcelResultExcel(data).then(res => {
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a"); //我们用模拟q标签点击事件
        const fileName = "导出体测成绩"; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
        if (res.code === 200) {
          this.$message.success('导入成功!')
          this.exportPhoneDialog = false
        } else {
          console.log('AAA');
          // this.$message.error(res.message)
        }
      })
    },
    dialogSwitch() {
      this.exportPhoneDialog = true;
    },
    //判断上传的文件
    exceedFile(files, fileList) {
      this.$message.warning(
          `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    async fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过10M");
      }
      if (extension === "xlsx" && size < 10) {
        this.sendEmailError = [];
        this.fileList.push(file.raw);
      }
      this.excelFile = file.raw
      let data = await this.readFile(file.raw);
      const workbook = XLSX.read(data, {
        // 以字符编码方式解析
        type: 'binary'
      })
      // 获取第一张表
      const excelName = workbook.SheetNames[0]
      const exl = XLSX.utils.sheet_to_json(workbook.Sheets[excelName]) // 生成json表格内容
      console.log(exl);
      this.number = exl.length
      console.log(this.number);
      // 将 JSON 数据挂到 data 里
      let arr = []
      exl.forEach(item => {
        arr.push(item.name)
      })
    },
    //读取文件
    readFile(file) {
      //文件读取
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
  },
}
</script>

<style scoped>

</style>
